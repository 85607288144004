export default defineNuxtRouteMiddleware((to, _) => {
  const doctorId = to.params.id

  if (!doctorId || Array.isArray(doctorId)) return
  const doctorIdInt = parseInt(doctorId)
  if (!redirectJson[doctorIdInt]) return

  return navigateTo(
    {
      path: to.path.replace(
        /(doctors\/)([0-9]+)/g,
        `$1${redirectJson[doctorIdInt]}`
      ),
      query: to.query,
    },
    {
      redirectCode: 301,
    }
  )
})

const redirectJson: { [key: number]: number } = {
  17353: 17402,
}
